// exports.onClientEntry = () => {
//   // Check if the page was reloaded
//   if (window.performance && performance.navigation.type === 1) {
//     window.scrollTo(0, 0);
//   }
// };

import "./src/styles/global.css";
// gatsby-browser.js
// gatsby-browser.js
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   if (location !== prevLocation) {
//     window.scrollTo(0, 0);
//   }
// };
// gatsby-browser.js

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation || location.pathname !== prevLocation.pathname) {
    // Navigating to a new page or re-clicking the same link
    window.scrollTo(0, 0); // Scroll to the top
  }
};

export const onClientEntry = () => {
  if (typeof window !== "undefined" && "scrollRestoration" in window.history) {
    // Enable the browser to handle back/forward navigation scroll position
    window.history.scrollRestoration = "auto";
  }
};
